import React from 'react';
import {useTranslation} from 'react-i18next';
import '../styles/topbar.scss';

const getStepClass = (currentPage, item) => {
	let stepClass = 'step';
  const isCurrentStep = Array.isArray(item.page) ? item.page.includes(currentPage) : currentPage === item.page;

	const current = steps.find((item) => {
		return Array.isArray(item.page) ? item.page.includes(currentPage) : currentPage === item.page;
	});

	if (current.step > item.step) {
		stepClass += ' previous-step';
	}

	return isCurrentStep ? `${stepClass} current-step` : stepClass;
};

const steps = [
	{
		step: 1,
		page: 'list',
		titleId: 'payment_methods',
	},
	{
		step: 2,
		page: ['crypto', 'payment', 'requisites', 'bank'],
    titleId: 'payment',
	},
	{
		step: 3,
		page: 'status',
    titleId: 'status',
	},
];

const getStep = (currentPage, t) => {
	return steps.map((item) => {
		const key = Array.isArray(item.page) ? item.page.join('_'): item.page;

		return (
			<div key={key} className={getStepClass(currentPage, item)}>
				<div className={'step-number'}>
					<div className={'step-number-inner'}>
						{item.step}
					</div>
				</div>
				<div className={'step-title'}>{t(item.titleId)}</div>
			</div>
		);
	});
};

const Topbar = (props) => {
  const {t} = useTranslation();
	const {screen, currentPage} = props;
	const page = currentPage === 'card' ? 'payment': currentPage;

	return (
		<div className={`topbar topbar-${screen}`}>
			<div className={'topbar-inner'}>
				{getStep(page, t)}
			</div>
		</div>
	);
};

export default Topbar;
