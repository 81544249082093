import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {formatUrlid} from '../utils/functions';
import CopyBlock from './CopyBlock';
import classNames from 'classnames/bind';

const SidebarContent = (props) => {
  const orderAmount = useSelector((state) => state.orderAmount.instance);
  const {t} = useTranslation();
  const {isOpen, order} = props;
  const sideBarClass = isOpen ? 'sidebar-open' : 'sidebar-close';
  const {
    Urlid,
    Amount,
    CurrencyCode,
    OrderDescription,
    MerchantDescription,
    ExternalOrderID,
    ImageUrl,
  } = order;

  return (
    <div className={`sidebar-inner ${sideBarClass}`}>
      <div className={'sidebar-main'}>
        <div className={classNames('sidebar-img', {
          default: !ImageUrl,
        })}>
          <img src={ImageUrl ? ImageUrl : "./img/icons/shopping-bag.svg"} alt="sidebar-img"/>
        </div>
        <span>{`${Amount} ${CurrencyCode}`}</span>
        {orderAmount && orderAmount.isDifferentCurrency ? (
          <span
            className={'sub-amount'}>{`(${orderAmount.paymentOrderAmount.Amount} ${orderAmount.paymentOrderAmount.CurrencyCode})`}</span>
        ) : null}
      </div>
      <div className={'sidebar-block'}>
        <div className={'sidebar-block-title'}>
          {`${t('transaction')}:`}
        </div>
        <div className={'sidebar-block-text with-copy'}>
          {formatUrlid(Urlid)}
          <CopyBlock text={Urlid}/>
        </div>
      </div>
      <div className={'sidebar-block'}>
        <div className={'sidebar-block-title'}>
          {`${t('order')}:`}
        </div>
        <div className={'sidebar-block-text'}>
          {ExternalOrderID}
        </div>
      </div>
      <div className={'sidebar-block'}>
        <div className={'sidebar-block-title'}>
          {`${t('description')}:`}
        </div>
        <div className={'sidebar-block-text'}>
          {OrderDescription}
        </div>
      </div>
      <div className={'sidebar-block'}>
        <div className={'sidebar-block-title'}>
          {`${t('merchant')}:`}
        </div>
        {/*<div>
					{MerchantDescription}
				</div>*/}
        {MerchantDescription.split('\n').map((item, i) => (
          <p
            key={i}
            className={'sidebar-block-info'}>
            {item}
          </p>
        ))}
      </div>
    </div>
  );
};

export default SidebarContent;
